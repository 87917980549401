import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";
import { getPacks } from "../../../redux/pack/pack.actions";

import Pagination from "../../../MyComponents/Pagination/Pagination";


import UpdateSousAdmin from "./popups/updatePack";



const Packs = () => {
  const packs = useSelector((state) => state.PackReducer.packs);
  const dispatch = useDispatch();

  const [Item, setItem] = useState(...packs);


  
  const [updDialogue, setUpdtDialog] = useState(false);
  const ddt = useRef(null);

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(getPacks());
    
  };
  useEffect(() => {
    console.log("packss",packs)
    }, [packs]);
  useEffect(() => {
    reloadData();
  }, []);
  //-------------------------------- Handle Opens -------------------------------

 
  const openUpdtDialogue = (row) => {
    setUpdtDialog(true);
    setItem({ ...row });
  };

  //-------------------------------- Handle Close -------------------------------
  const handleClose = () => {
    setItem({ ...packs });
    setUpdtDialog(false);
    reloadData()
  };

  //-------------------------------- Header of Page -------------------------------

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Packs</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
 
        </div>
      </React.Fragment>
    );
  };



  const nom = (row) => {
    console.log("row;")
    return `${row?.nom}`;
  };

  const tnd = (row) => {
    return row?.dt+' TND' ;
  };

  const eur = (row) => {
    return row?.eur+" EUR" ;
  };

  const usd = (row) => {
    return row?.usd+' USD' ;
  };

  const actionBodyTemplate = (row) => {
    return (
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
  
        <Button
          icon="pi pi-pencil"
          tooltip="Update pack"
          tooltipOptions={{ position: "bottom" }}
          className="p-button-rounded p-button-text p-button-info mr-0"
          onClick={() => openUpdtDialogue(row)}
        />

      </div>
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

     
          {Item && updDialogue && (
            <UpdateSousAdmin
              open={updDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Updating Pack ${Item.nom}`}
            />
          )}

       

    

   

          <DataTable
            ref={ddt}
            value={[...packs]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Sous Admin found."
          
            responsiveLayout="scroll"
          >
      
            <Column field="nom" header="nom" body={nom} />
            <Column field="dt" header="dt" body={tnd} />
            <Column field="eur" header="eur" body={eur} />
            <Column field="usd" header="usd" body={usd} />
            <Column body={actionBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default Packs;

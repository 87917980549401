import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./DelForm.css";
import { useTranslation } from "react-i18next";

import useWindowSize from "../../../../hooks/useWindowSize";
import { Col, Row } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";


function OpenDescription(props) {

    const PopupSize = () => {
        switch (size) {
            case "xl":
                return "800px";
            case "lg":
                return "800px";
            case "md":
                return "500px";
            case "sm":
                return "500px";
            case "xs":
                return "98%";
            default:
                return "80%";
        }
    };
    const { t } = useTranslation();

    const { open, handleClose, description, value, title } = props;
    const payload = useSelector((state) => state.UniversityReducer.payload);

    const [item, setItem] = useState({ ...value });
    const size = useWindowSize();


    useEffect(() => {
        console.log(value);
        setItem({ ...value });
    }, [value]);





    const DialogFooter = (
        <div className="popup-footer">
            <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-outlined"
                onClick={handleClose}
               
                style={{ color: "#f4ba41ff", backgroundColor: "#ffffff" }}
            />
        </div>
    );

    const DialogHeader = (
        <div  style={{ overflowWrap: "break-word", wordWrap: "break-word", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h1>{item.nom}</h1> 
        </div>
    );


    return (
        <div className="overlay">
            <Dialog
                visible={open}
                style={{ width: PopupSize() }}
                header={DialogHeader}
                modal
                className="p-fluid popup-container"
                footer={DialogFooter}
                onHide={handleClose}
            >
                <div className="flex align-items-center justify-content-start" style={{ overflow: "hidden", padding: "10px" }}>

                    <Row className="mt-5">
                        <Col md={12} xs={12}>
                            <p style={{ fontSize: "14px" }}>{item.description}</p>
                        </Col>
                        <h5 className="title_second color_second description_formation" style={{ fontSize: "14px", maxWidth: "450px" }}>
                          <span>Price: {item.price} {item.devise}</span>
                          <span style={{ margin: "0 10px" }}>|</span> 
                          <span>Duration: {item.duree} weeks</span>
                          <span style={{ margin: "0 10px" }}>|</span>
                          <span>{item.avecBac ? "with bac" : "without bac"}</span>
                      </h5>
                    </Row>

                </div>
            </Dialog>
        </div>
    );
}

export default OpenDescription;


